import {createApp, ref} from 'vue'
import App from './App.vue'
import './style/main.scss'
import {store} from './utils/store.js'
import router from './utils/router.js'

import {registerSW} from 'virtual:pwa-register'
import {notify} from './utils/common.js'
import { MotionPlugin } from '@vueuse/motion'


let doingUpdate = ref(false)

const updateSW = registerSW({
  async onNeedRefresh() {

    if (doingUpdate.value) {
      return false
    }

    let p = confirm('检测到远程内容有更新，是否重新载入？')

    if (!p) {
      return false
    }

    doingUpdate.value = true

    await updateSW()
  },
  onOfflineReady() {
    console.log('offline ready')
  }
})

if (location.hostname === 'zy.jw1.dev') {
  let originalHref = location.href
  let newHref = originalHref.replace('zy.jw1.dev', 'www.zhiyan.dev')
  location.href = newHref
}

// Function to update theme color based on current color scheme
function updateThemeColor() {
  const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
  const themeColor = isDarkMode ? '#121212' : '#ffffff'
  const metaThemeColor = document.querySelector('meta[name="theme-color"]')
  
  if (metaThemeColor) {
    metaThemeColor.setAttribute('content', themeColor)
    console.log('Theme color updated to:', themeColor)
  } else {
    console.warn('meta[name="theme-color"] element not found')
  }
}

// Initial theme color update
setTimeout(updateThemeColor, 50)

// Persistent check for theme changes
window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
  const newColorScheme = event.matches ? 'dark' : 'light'
  console.log('Color scheme changed to:', newColorScheme)
  
  // Run multiple checks after a mode change is detected
  // This helps ensure the theme color is properly updated in PWA mode
  let checkCount = 0
  const maxChecks = 10
  const checkInterval = 500 // 500ms between checks
  
  const persistentCheck = setInterval(() => {
    updateThemeColor()
    checkCount++
    
    if (checkCount >= maxChecks) {
      clearInterval(persistentCheck)
      console.log('Completed persistent theme color checks')
    }
  }, checkInterval)
})

const app = createApp(App)

app.use(store)
app.use(router)
app.use(MotionPlugin)

app.mount('#app')
